input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* override for bootstrap button outline */
button {
    outline: none !important;
}

.mapboxgl-canvas {
    position: relative !important;
    border-radius: 4px;
}