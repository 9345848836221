.map-container {
  width: 100%;
  height: 350px;
  border-radius: 4px;
}

.mapboxgl-marker {
  cursor: pointer;
}

.btn-shout {
    margin: 0 auto;
    background-color: #4dada0;
    border-color: #4dada0;
    color: white;
  }

.btn-draw {
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 5;
}

.btn-drawing {
  border-color: #33393b;
  background-color: #33393b !important;
  box-shadow: none;
}
